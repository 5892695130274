<template>
  <header id="nav" class="header">
      <div class="header__container">
          <router-link :to="{name:'Home'}" class="header__logo">
              <img src="@/assets/logo/pump-and-drink-color-r.png" alt="logo pump and drink" class="header__logo--img">
          </router-link>
          <nav class="header__content">
              <ul class="header__nav header__content--item">
                  <li v-for="menu in menuFront" :key="menu.target" :class="{'header__nav--selected': menu.target === routeName ? true: false}" class="u-hide-phone">
                      <router-link :to="{name:menu.target}" class="">
                          {{menu.text}}
                      </router-link>
                  </li>
              </ul>
              <button class="header__menu header__content--item" @click="toggleSide" aria-label="open side menu">
                  <div class="header__menu--bar">&nbsp;</div>
              </button>
              <div class="header__lang--container header__content--item u-hide-small">
                <button class="header__lang " @click="toggleLang" >
                    <Svg className="header__lang--svg" icon="world"></Svg>
                    {{$store.state.languageName}}
                </button>
                <ul v-if="displayLang">
                    <li v-for="lang in langues" @click="setLanguage(lang.locale)">
                        {{lang.name}}
                    </li>
                </ul>
              </div>
              
          </nav>
      </div>
        <div  class="side__container" :class="{'side__container--hide': !displaySide, 'side__container--show': displaySide}">
            <button class="side__quit" aria-label="close side menu" @click="hideSide">
                <Svg icon="delete" className="side__quit--svg"></Svg>
            </button>
            <div class="side__logo">
                <img src="@/assets/logo/logo-pad-white.svg" alt="logo pump&drink">
            </div>
            <ul class="side">
                <li v-for="s in menuSide" :key="s.target">
                    <router-link :to="{name:s.target}" class="side__btn" @click="displaySide=false">
                        {{s.text}}
                    </router-link>
                </li>
            </ul>
            <a href="mailto:info@pumpanddrink.com" class="side__contact">
                <Svg className="header__lang--svg" icon="email"></Svg> info@pumpanddrink.com
            </a>
            <div class="side__lang">
                <ul>
                    <li v-for="lang in langues" :key="lang.locale" @click="setLanguage(lang.locale)">
                        <button>
                            {{lang.name}}
                        </button>
                    </li>
                </ul>
            </div>
           

        </div>
  </header>
</template>

<script>
import { computed, ref, watch } from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';

import { useI18n } from 'vue-i18n';

import {languages} from '@/composables/languages';

import Svg from './utils/Svg';
export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        const {locale} = useI18n()

        const {t} = useI18n({
            messages:{
              en: require('../locales/en/header.json'),
              fr: require('../locales/fr/header.json'),
              pt: require('../locales/pt/header.json'),
              sw: require('../locales/sw/header.json')
            }
        })

        const langues = languages()
        

        const displaySide = ref(false);
        const displayLang = ref(false);
        

        const route = useRoute();
        const routeName = ref(route.name);

        watch(route, (n, o) => {
            routeName.value = n.name;
        })


        const hideSide = () => {
            displaySide.value = false;
        }
        const toggleSide = () => {
            displaySide.value = !displaySide.value;
        }
        const toggleLang = () => {
            displayLang.value = !displayLang.value;
        }
        

        const setLanguage = (lang) => {
            locale.value = lang;
            displayLang.value = false;

            store.state.language = lang;

            //Router
            route.query.locale = lang;
            history.pushState({}, null,  route.path+'?locale='+lang)
        }

        const menuFront = computed(() => [
              {
                "text": t('home'),
                "target": "Home"
            },
            {
                "text": "Pump&Drink®",
                "target": "Pump"
            },
            {
                "text": t('products'),
                "target": "Products"
            },
            {
                "text": t('projects'),
                "target": "Project"
            },
        ]);
        const menuSide = computed(() => [
            {
                "text": t('home'),
                "target": "Home"
            },
            {
                "text": "Pump&Drink®",
                "target": "Pump"
            },
            {
                "text": t('projects'),
                "target": "Project"
            },
            {
                "text": t('team'),
                "target": "Team"
            }
        ]);



        return {
            displayLang,
            displaySide, 
            hideSide, 
            langues,
            menuFront,
            menuSide, 
            routeName,
            setLanguage,
            toggleLang,
            toggleSide
        }
    }
}
</script>

<style lang='scss'>

@import '../style/main';

.header{
    height: $nav-height;
    border-bottom: .3rem solid $color-primary;
    background-color: $color-white;
    box-shadow:$shadow;
    width: 100%;
    z-index: 1000;

    position: fixed;
    left: 0;
    top: 0;

    &__container{
        display: flex;
        max-width: $page-max-width;
        margin: 0 auto;
        padding: 1.5rem 0;
        height: 100%;

        @include respond(phone){
            margin: 0 $margin-section-phone
        }
    }

    &__logo{
        height: 100%;
        @include respond(small){
                display: flex;
                align-items: center;
            }
        
        &--img{
            @include respond(small){
                height: 80%;
            }
            height: 100%;
            width: auto;
        }
    }

    &__content{
        display: flex;
        margin-left: auto;
        font-size: 1.6rem;

        &--item{
            &:not(:last-child){
                margin-right: 2rem;
            }
        }
    }
    &__nav{
        display: flex;
        align-items: center;
        & li{
            @include hoverUnderline;
            text-transform: uppercase;
            list-style: none;
            &:not(:last-child){
                margin-right: 1.5rem;
            }
        }

        & a{
            color:inherit;
            text-decoration: none;
            letter-spacing: .1rem;
        }

        &--selected{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: calc(100% + .3rem);
                left: 0;
                width: 100%;
                height: .1rem ;
                background-color: currentColor;
        
                transition: all .2s;
            }
        }
    }
    &__menu{
        @include buttonNoStyle;
        width: 4rem;
        height: .4rem;
        
        height: 100%;

        &--bar{
            position: relative;
            background-color: $color-primary;
            height: 3px;

             &::before{
                content:"";
                position: absolute;
                left: 0;
                top: -1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                
            }
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: 1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
            }
        }

        &:hover > &--bar{
            &::before{
                top: -1.5rem;
            }
            &::after{
                top: 1.5rem;
            }
        }
    }

    &__lang{
        @include buttonNoStyle;

        display: flex;
        align-items: center;
        border: 1px solid currentColor;
        align-self: center;
        padding: .8rem 1.4rem;
        border-radius: 5px;

        transition: all .2s;

        &:hover{
            background-color: $color-background-grey;
        }

        &--svg{
            @include svg(2rem);
            margin-right: 1.5rem;
        }

        &--container{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & ul{
                position: absolute;
                top: calc(100%);
                left: 0;
                width: 100%;
                border-radius: 3px;
                border: 1px solid currentColor;
                box-shadow: $shadow;
            }

            & li{
                list-style: none;
                padding: .6rem 1rem;
                background-color: $color-white;
                cursor: pointer;
                &:not(:last-child){
                    border-bottom: 1px solid currentColor;
                }

                &:hover{
                    background-color: $color-background-grey;
                }
            }
        }

        
    }
}
.side{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & li{
        list-style: none;
        width: 100%;        
    }

    & a{
        @include buttonNoStyle;
        display: flex;
        padding: 1.6rem 2.4rem;
        width: 100%;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.6rem;
        letter-spacing: .2rem;

        &:hover{
            background-color: $color-white;
            color:$color-primary;
        }
    }

     &__container{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 30vw;
        height: 100vh;
        background-color: $color-primary;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        color: $color-white;

        &--hide{
            transition: all .4s;
            right: -100vw;
        }

        &--show{
             transition: all .4s;
            right: 0vw;
        }
        @include respond(phone){
            width: 100vw;
        }
        
    }

    &__logo{
        width: 50%;
        margin: 0 auto;
        & img{
            width: 100%;
            height: auto;
        }
    }

    &__quit{
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        transition: all .2s;

        &--svg{
            @include svg(2.4rem);
        }

        &:hover{
            transform: scale(1.1);
        }
    }

    &__lang{
        display: flex;
        width: 100%;
        & ul{
            width: 100%;
            display: flex;
            justify-content: center;
        }

        & li{
            position: relative;
            list-style: none;
            padding: .2rem 2rem;

            &:not(:last-child){
                &::after{
                    content:'';
                    position: absolute;
                    left: calc(100%);
                    top: 0;
                    width: .2rem;
                    height: 100%;
                    background-color: currentColor;
                }
            }
        }

        & button{
            @include buttonNoStyle;
            @include hoverUnderline;
        }
    }

    &__contact{
        
      
            text-decoration: none;
            color:$color-primary;
            text-transform: uppercase;
            letter-spacing: .1rem;
            display: flex;
            align-items: center;

            padding: 1.6em 2.4rem;
            margin-bottom: 2rem;
            border-radius: 3px;
            background-color: $color-white;
            transition: all .2s;

            &:hover{
                transform: scale(1.05);
            }
            // justify-content: center;
        
    }
}

</style>