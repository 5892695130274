<template>
  <div class="award">
      <div class="award__image">
          <img src="@/assets/icons/solar-impulse-23.png" :alt="t('alt')">
      </div>
      <div class="award__content">
          <h2>
              {{t('award')}}
          </h2>
          <h3>
              {{t('title')}}
          </h3>
          <p>
              {{t('text')}}
          </p>
          <a href="https://solarimpulse.com/efficient-solutions/pump-drink" target="_blank" class="award__link" rel="noopener noreferrer">
            <Svg icon="right-arrow" className="award__link--svg"></Svg>
            {{t('cta')}}
          </a>
      </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import Svg from '@/components/utils/Svg'

export default {
    components: {
        Svg
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/impulse.json'),
              fr: require('@/locales/fr/impulse.json'),
              pt: require('@/locales/pt/impulse.json'),
              sw: require('@/locales/sw/impulse.json')
            }
        })
        return {t}
    }
}
</script>

<style lang='scss' scoped>
@import '../../style/main';
.award{
    display: flex;
    align-items: center;

     @include respond(phone){
        flex-direction: column;
    }

    // background-color: ;
    &__image{
        flex: 0 0 18%;
        margin-right: 4rem;
        & img{
            width: 100%;
            height: auto;
        }

        @include respond(phone){
            width: 40%;
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }

    &__content{
        @include respond(phone){
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & h2{
            color:$color-secondary;
            text-transform: uppercase;
            font-size: 6rem;
            font-weight: 400;
            letter-spacing: .3rem;
            line-height: 6.5rem;

            @include respond(phone){
                font-size: 4rem;
                line-height: 4.5rem;
                text-align: center;
            }
        }

        & h3{
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
            margin-bottom: 1rem;
            @include respond(phone){
                text-align: center;
            }
        }
        & p{
            margin-bottom: 2rem
        }

        
    }

    &__link{
        display: flex;
        align-items: center;
        text-decoration: none;
        color:inherit;
        &--svg{
            @include svg(2rem);
            margin-right: 2rem;
        }
        transition: all .3s;
        &:hover{
            transform:translateX(1rem)
        }
    }
}

</style>